import "./CardPage.css";
import { Table, Breadcrumb } from "react-bootstrap";
import s from "./CardPage.module.scss";
import { Link, useParams } from "react-router-dom";
import {useEffect, useMemo, useState} from "react";
import scrollIntoView from "scroll-into-view";
import { useDispatch, useSelector } from "react-redux";
import Select from "./Select/Select";
import { loadMainProduct } from "../../store/async/asyncCategories";
import { useTranslation } from "react-i18next";
import DOMPurify from "dompurify";

export default function CardPage() {
  const { t } = useTranslation();
  const { language } = useSelector((store) => store.language);
  const { product, isLoading } = useSelector((store) => store.products);
  const dispatch = useDispatch();
  const { id } = useParams();
  const [prevUrl, setPrevUrl] = useState('');
  const [selectOpen, setSelectOpen] = useState();
  const macroSizes = useMemo(() => {
    if (product?.macroSizes) {
      return DOMPurify.sanitize(product.macroSizes);
    }
    return null;
  }, [product]);

  document.body.addEventListener("click", () => {
    setSelectOpen(-1);
  });

  useEffect(() => {
    scrollIntoView(document.body.querySelector("#root"), {
      time: 0,
      align: {
        top: 0.1,
      },
    });

    dispatch(loadMainProduct({ id, lang: language }));
  }, [id, language]);

  useEffect(() => {
    console.log(product);
    if (product?.subCategory) {
      setPrevUrl(
        "/products?categoryId=" + product.id + "&subcategoryId=" + product.id
      );
    } else {
      setPrevUrl("/products?categoryId=" + product.id);
    }
  }, [product]);

  if (isLoading) {
    return (
        <div className={s.loading}>
          <img src="/loading.gif" alt="Загрузка" />
        </div>
      );
  }

  return (
    <>
      {product ? (
        <>
          <div className="card-page-container">
            <Breadcrumb
              className="custom-breadcrumb"
              style={{ marginTop: "38px" }}
            >
              <span
                className={"breadcrumb-item"}
                onClick={(e) => e.preventDefault()}
              >
                <Link to="/">{t("Меню Главная")}</Link>
              </span>
              <span
                className={"breadcrumb-item"}
                onClick={(e) => e.preventDefault()}
              >
                <Link to="/">{t("Меню Компания")}</Link>
              </span>
              <Breadcrumb.Item href={prevUrl}>
                {product.category &&
                  product.category.name.split("")[0].toUpperCase() +
                    product.category.name
                      .split("")
                      .slice(1, product.category.name.split("").length)
                      .join("")}
              </Breadcrumb.Item>
              <Breadcrumb.Item href="/" active>
                {product.name}
              </Breadcrumb.Item>
            </Breadcrumb>
            <br />
            {product.name && (
              <>
                <div className={s.flexDirection}>
                  <div>
                    <div className={s.wrapper}>
                      <div className={s.imageWrapper}>
                        <img src={product.image} alt="Кабель" />
                      </div>
                      <div className={s.mainContent}>
                        <div>
                          <div className="cable-name">
                            <span style={{ fontWeight: "800" }}>
                              {product.name}
                            </span>
                          </div>
                        </div>
                        <div className={s.info}>
                          <div className={s.infoText}>
                            <div className={s.title}>{t("НАРУЖНАЯ ОБОЛОЧКА")}</div>
                            <div className={s.paragraph}>
                              {product.insulation ||
                              product.cableSheath ||
                              product.screen ? (
                                <div className={s.textWrapper}>
                                  <div className={s.mainParagraph}>
                                  {t("Изоляция")} — {product.insulation}
                                    {product.cableSheath || product.screen ? (
                                      <ul
                                        style={{
                                          listStyle: "none",
                                          marginTop: "0.5em",
                                          paddingLeft: "0",
                                        }}
                                      >
                                        {product.cableSheath && (
                                          <li>
                                            <b>{t("Оболочка кабеля")}</b> —{" "}
                                            {product.cableSheath}
                                          </li>
                                        )}
                                        {product.screen && (
                                          <li>
                                            <b>{t("С экраном")}</b> — {product.screen}
                                          </li>
                                        )}
                                      </ul>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={`${s.textWrapper} ${s.modify}`}>
                      <div className={s.mainTitle}>{t("НАЗНАЧЕНИЕ")}</div>
                      <div className={s.mainParagraph}>{product.purpose}</div>
                    </div>
                    <div className={s.textWrapper}>
                      <div className={s.mainTitle}>{t("КОНСТРУКЦИЯ")}</div>
                      <div className={s.mainParagraph}>
                        {product.construction}
                      </div>
                    </div>
                    {product.insulation ||
                    product.cableSheath ||
                    product.screen ? (
                      <div className={s.textWrapper}>
                        <div className={s.mainTitle}>{t("ИЗОЛЯЦИЯ")}</div>
                        <div className={s.mainParagraph}>
                          Изоляция — {product.insulation}
                          {product.cableSheath || product.screen ? (
                            <ul
                              style={{
                                listStyle: "none",
                                marginTop: "0.5em",
                                paddingLeft: "0",
                                marginBottom: "0",
                              }}
                            >
                              {product.cableSheath && (
                                <li>
                                  <b>{t("Оболочка кабеля")}</b> — {product.cableSheath}
                                </li>
                              )}
                              {product.screen && (
                                <li>
                                  <b>{t("С экраном")}</b> — {product.screen}
                                </li>
                              )}
                            </ul>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                {product.specification && (
                  <div className={`${s.textWrapper} ${s.custom}`}>
                    <div className={s.mainTitle}>{t("УСЛОВИЯ ЭКСПЛУАТАЦИИ")}</div>
                    <div className={s.mainParagraph}>
                      {product.specification.includes('HTML') && (
                    <div className="exspl" dangerouslySetInnerHTML={{ __html: product.specification.replace(/HTML/gi, '') }} />
                    )}
                      {!product.specification.includes('HTML') && (
                      <ul className={s.mainNav}>
                        {product.specification.split("•").map((prod, idx) => (
                          <li key={idx}>
                            {idx >= 1 && "•"} {prod}
                          </li>
                        ))}
                      </ul>
                      )}
                    </div>
                  </div>
                )}
                {product.exploitation && (
                  <div className={`${s.textWrapper} ${s.custom}`}>
                    <div className={s.mainTitle}>
                    {t("ТЕХНИЧЕСКИЕ ХАРАКТЕРИСТИКИ")}
                    </div>
                    <div className={s.mainParagraph}>
                      <ul className={s.mainNav}>
                        {product.exploitation.split("•").map((prod, idx) => (
                          <li key={idx}>
                            {idx >= 1 && "•"} {prod}
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                )}

                {macroSizes && (
                    <div className="table-responsive" dangerouslySetInnerHTML={{ __html: macroSizes }} />
                )}

                {(product && product.sizes && !product.macroSizes) && (
                  <div className={`${s.textWrapper} ${s.custom}`} id="scroll">
                    <div className={s.mainTitle}>{t("МАКРОРАЗМЕРЫ")}</div>
                    <div className={s.mainParagraph}>
                      <Table responsive className="table">
                        <thead>
                          <tr>
                            <th>{t("Номинальное сечение жил")}</th>
                            <th>{t("Число жил")}</th>
                            <th>{t("Максимальный диаметр")}</th>
                            <th>{t("Масса")}</th>
                            <th>{t("Конструкция жилы")}</th>
                            {/*<th></th>*/}
                          </tr>
                        </thead>
                        <tbody>
                          {product.sizes.map((size, idx) => (
                            <tr key={idx}>
                              <td onClick={(e) => e.stopPropagation()}>
                                {size.nominalCrossSection ? (
                                  size.nominalCrossSection.length > 1 ? (
                                    <Select
                                      values={size.nominalCrossSection}
                                      selectOpen={selectOpen}
                                      setSelectOpen={setSelectOpen}
                                      idx={`${idx}`}
                                    />
                                  ) : size.nominalCrossSection.length === 1 ? (
                                    size.nominalCrossSection
                                  ) : (
                                    "-"
                                  )
                                ) : (
                                  "-"
                                )}
                              </td>
                              <td onClick={(e) => e.stopPropagation()}>
                                {size.numberOfCores ? (
                                  size.numberOfCores.length > 1 ? (
                                    <Select
                                      values={size.numberOfCores}
                                      selectOpen={selectOpen}
                                      setSelectOpen={setSelectOpen}
                                      idx={`${idx}`}
                                    />
                                  ) : size.numberOfCores.length === 1 ? (
                                    size.numberOfCores
                                  ) : (
                                    "-"
                                  )
                                ) : (
                                  "-"
                                )}
                              </td>
                              <td>{size.maxDiameter || "-"}</td>
                              <td onClick={(e) => e.stopPropagation()}>
                                {size.weight ? (
                                  size.weight.length > 1 ? (
                                    <Select
                                      values={size.weight}
                                      selectOpen={selectOpen}
                                      setSelectOpen={setSelectOpen}
                                      idx={`${idx}`}
                                    />
                                  ) : size.weight.length === 1 ? (
                                    size.weight
                                  ) : (
                                    "-"
                                  )
                                ) : (
                                  "-"
                                )}
                              </td>

                              <td onClick={(e) => e.stopPropagation()}>
                                {size.coreConstruction ? (
                                  size.coreConstruction.length > 1 ? (
                                    <Select
                                      values={size.coreConstruction}
                                      selectOpen={selectOpen}
                                      setSelectOpen={setSelectOpen}
                                      idx={`${idx}`}
                                    />
                                  ) : size.coreConstruction.length === 1 ? (
                                    size.coreConstruction
                                  ) : (
                                    "-"
                                  )
                                ) : (
                                  "-"
                                )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </>
      ) : (
        "Loading..."
      )}
    </>
  );
}
