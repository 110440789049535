import "./ProductPage.css";
import { Col, Row, Breadcrumb } from "react-bootstrap";
import s from "./ProductPage.module.scss";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import {useEffect, useState} from "react";
import scrollIntoView from "scroll-into-view";
import { useDispatch, useSelector } from "react-redux";
import {
  getCategories,
  getIndustries,
  getSubCategories,
  loadCategoriesProducts,
  loadIndustryProducts,
  loadSubCategoriesProducts,
} from "../../store/async/asyncCategories";
import { generatePathLocation } from "./filterLogic";
import Dropdown from "./Dropdown/Dropdown";
import PaginationProducts from "./PaginationProducts/PaginationProducts";
import Search from "../../ui/Search/Search";
import LeadersDropdown from "./LeadersDropdown";
import { t } from "i18next";
import {
  changeActiveLang,
  changeLastIdCat,
  setProducts,
} from "../../store/reducers/CategoriesReducer";
import { clearProduct } from "../../store/reducers/ProductsReducer";
import { resetData } from "../../store/reducers/cabelSearchReducer";
import {cabelSearch} from "../../store/async/asyncSearch.js";
import {getMostPopularCategories} from "./data.js";
import {useTranslation} from "react-i18next";

export default function ProductPage() {
  // react-router-dom hooks
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const {t} = useTranslation();

  const [filter, setFilter] = useState(1);
  const [activePage, setActivePage] = useState(() => {
    return searchParams.has('page') ? +searchParams.get('page') - 1 : 0
  });
  const categories = useSelector((store) => store.categories);
  const { language } = useSelector((store) => store.language);
  const { data: productSearchResult } = useSelector((store) => store.search);
  const { product } = useSelector((store) => store.products);
  const leaders = getMostPopularCategories(t);

  useEffect(() => {
    dispatch(clearProduct());
  }, []);

  useEffect(() => {
    const query = searchParams.get("q");
    if (query) {
      dispatch(cabelSearch(query));
      setActivePage(0);
    }
  }, [dispatch, searchParams]);


  useEffect(() => {
    if (productSearchResult?.length) {
      dispatch(setProducts(productSearchResult));
      dispatch(resetData());
    }
  }, [productSearchResult]);

  // предыдующая ссылка
  const [prevUrl, setPrevUrl] = useState();
  // активные параметры фильтрации (id, id)
  const [activeCategory, setActiveCategory] = useState([null, null]);
  const [activeIndustry, setActiveIndustry] = useState("");

  useEffect(() => {
    setTimeout(() => {
      scrollIntoView(document.body.querySelector(".product-page-container"), {
        time: 0,
        align: {
          top: 0.1,
        },
      });
    }, 0);
  }, [filter]);

  useEffect(() => {
    Promise.all([
      dispatch(getCategories(language)),
      dispatch(getSubCategories(language)),
      dispatch(getIndustries(language)),
    ]);
  }, [language]);

  useEffect(() => {
    scrollIntoView(document.body.querySelector(".product-page-container"), {
      time: 0,
      align: {
        top: 0.1,
      },
    });

    if (searchParams.get("categoryId")) {
      if (searchParams.get("subcategoryId")) {
        setActiveCategory([
          searchParams.get("categoryId"),
          searchParams.get("subcategoryId"),
        ]);

        dispatch(
          loadSubCategoriesProducts({
            id: searchParams.get("subcategoryId"),
            lang: language,
          })
        );
      } else {
        setActiveCategory([searchParams.get("categoryId"), null]);
        if (
          categories.lastIdCat === searchParams.get("categoryId") &&
          categories.activeLang === language
        )
          return;

        dispatch(changeLastIdCat(searchParams.get("categoryId")));
        dispatch(changeActiveLang(language));
        dispatch(
          loadCategoriesProducts({
            id: searchParams.get("categoryId"),
            lang: language,
          })
        );
      }
    }

    if (searchParams.get("industriesId")) {
      setActiveIndustry([searchParams.get("industiesId")]);

      dispatch(
        loadIndustryProducts({
          id: searchParams.get("industriesId"),
          lang: language,
        })
      );
    }
  }, []);

  useEffect(() => {
    if (activeCategory[1]) {
      setPrevUrl(
        "/products?categoryId=" +
          activeCategory[0] +
          "&subcategoryId=" +
          activeCategory[1]
      );
    } else {
      setPrevUrl("/products?categoryId=" + activeCategory[0]);
    }

    if (activeCategory[0]) {
      if (activeCategory[1]) {
        dispatch(
          loadSubCategoriesProducts({ id: activeCategory[1], lang: language })
        );
      } else {
        if (
          categories.lastIdCat === activeCategory[0] &&
          categories.activeLang === language
        )
          return;

        dispatch(changeLastIdCat(activeCategory[0]));
        dispatch(changeActiveLang(language));
        dispatch(
          loadCategoriesProducts({ id: activeCategory[0], lang: language })
        );
      }
    }

    setTimeout(() => {
      scrollIntoView(document.body.querySelector(".product-page-container"), {
        time: 0,
        align: {
          top: 0.1,
        },
      });
    }, 0);
  }, [activeCategory, language]);

  useEffect(() => {
    if (searchParams.get("industriesId"))
      dispatch(
        loadIndustryProducts({
          id: searchParams.get("industriesId"),
          lang: language,
        })
      );
  }, [activeIndustry, searchParams.get("industriesId")]);

  if (categories.isLoading) {
    return (
      <div className={s.loading}>
        <img src="/loading.gif" alt="Загрузка" />
      </div>
    );
  }

  return (
    <>
      <div className={s.searchMargin}>
        <Search />
      </div>
      <div className="product-page-wrapper">
        <div className="product-page-image"></div>
        <div className="product-page-container" id="products">
          <Breadcrumb
            className="custom-breadcrumb"
            style={{ marginTop: "38px" }}
          >
            <span
              className={"breadcrumb-item"}
              onClick={(e) => e.preventDefault()}
            >
              <Link to="/">{t("Меню Главная")}</Link>
            </span>
            {categories.activeSubCategoryName ? (
              <>
                <span
                  className={"breadcrumb-item"}
                  onClick={(e) => e.preventDefault()}
                >
                  <Link to="/products">{t("Меню каталог")}</Link>
                </span>
                <Breadcrumb.Item href={prevUrl}>
                  {t('allCategories')}
                </Breadcrumb.Item>
                <Breadcrumb.Item href="/" active>
                  {categories.activeSubCategoryName.split("")[0].toUpperCase() +
                    categories.activeSubCategoryName
                      .split("")
                      .slice(
                        1,
                        categories.activeSubCategoryName.split("").length
                      )
                      .join("")}
                </Breadcrumb.Item>
              </>
            ) : categories.activeCategoryName ? (
              <>
                <span
                  className={"breadcrumb-item"}
                  onClick={(e) => e.preventDefault()}
                >
                  <Link to="/products">{t("Меню каталог")}</Link>
                </span>
                <Breadcrumb.Item href={prevUrl} active>
                  {t(categories.activeCategoryName)}
                </Breadcrumb.Item>
              </>
            ) : (
              <Breadcrumb.Item active>{t("Меню каталог")}</Breadcrumb.Item>
            )}
          </Breadcrumb>
          <h1 className={s.categoryTitle}>
            {t('allCategories')}
          </h1>

          <Row className="product-page-row">
            <Col md={4} className={`${s.productWrapper} ${filter && s.active}`}>
              {filter === 1 ? (
                <Dropdown
                  setActiveCategory={setActiveCategory}
                  activeCategory={activeCategory}
                  filter={filter}
                  allCategories={categories.data}
                />
              ) : !filter ? (
                <>
                  {categories.industries.legal.map((cat) => (
                    <div
                      className={s.dropdown}
                      onClick={() => {
                        setActiveIndustry(cat.id);
                        setFilter(1);
                        navigate("?industriesId=" + cat.id);
                      }}
                    >
                      <div className={`${s.titleDropdown}`} id="dropdown-basic">
                        <span className="product-type-link">{cat.name}</span>
                      </div>
                    </div>
                  ))}
                </>
              ) : (
                <LeadersDropdown leaders={leaders} />
              )}
            </Col>
            <Col md={8} className="products">
              <div className={s.title}>{t("Меню каталог")}</div>
              <div className={s.tabs}>
                <span
                  className={`${s.tab} ${filter === 1 && s.active}`}
                  onClick={() => setFilter(1)}
                >
                  {t("По категориям")}
                </span>
                {/*<span
                  className={`${s.tab} ${!filter && s.active}`}
                  onClick={() => setFilter(0)}
                >
                  {t("По отраслям")}
                </span>
                <span
                  className={`${s.tab} ${filter === 2 && s.active}`}
                  onClick={() => setFilter(2)}
                >
                  {t("Лидеры продаж")}
                </span>*/}
              </div>
              {location.search === "" && filter === 1 ? (
                <>
                  {categories.data ? (
                    <div className="product-carousel-container">
                      <div className="commonLabels">
                        <p>{t("Сл ПРОДУКЦИЯ")}</p>
                      </div>
                      <div className="g-1 product-carousel-row">
                        {categories.data.map((data) => (
                          <div key={data.id} className="product-carousel-col">
                            <div
                              className="custom-link"
                              onClick={() => {
                                setActiveCategory([data.id, null]);
                                setActivePage(0);
                                navigate("/products?categoryId=" + data.id);
                              }}
                            >
                              <div className="product-carousel-card">
                                <figure className="fig">
                                  <div className="back">
                                    <div className="clip">
                                      <img
                                        className="img_default"
                                        src={"test-provod.png"}
                                        alt=""
                                      />
                                    </div>
                                  </div>
                                </figure>
                                <div className="product-carousel-card-body">
                                  <p>
                                    <span className="card-title modify">
                                      {data.name.split("")[0].toUpperCase() +
                                        data.name
                                          .split("")
                                          .slice(1, data.name.split("").length)
                                          .join("")}
                                    </span>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                      {/* <div className="catalogButton">
                        <Link
                          className="custom-button-product"
                          variant="danger"
                          to="/products"
                        >
                          {t("Перейти в каталог")}
                        </Link>
                      </div> */}
                    </div>
                  ) : (
                    ""
                  )}
                </>
              ) : filter === 1 ? (
                <>
                  <div className={s.cards}>
                    {categories.showProducts.length > 0
                      ? categories.showProducts.length <= 12
                        ? categories.showProducts.map((ind) => (
                            <div key={ind._id} className={s.card}>
                              <div className="product-carousel-card">
                                <figure className="fig">
                                  <div className="back">
                                    <div className="clip">
                                      <img
                                        className="img"
                                        src={
                                          ind.image
                                            ? ind.image.indexOf("https") > -1
                                              ? ind.image
                                              : process.env.REACT_APP_API +
                                                "/uploads/" +
                                                ind.image
                                            : "test-provod.png"
                                        }
                                        alt=""
                                      />
                                    </div>
                                  </div>
                                </figure>
                                <div className="product-carousel-card-body">
                                  <p>
                                    <span className="card-title modify">
                                      {ind.name}
                                    </span>
                                  </p>
                                </div>
                                <button
                                  onClick={() =>
                                    navigate("/products/" + ind.id)
                                  }
                                  className={s.button}
                                >
                                  {t("Кнопка Подробнее")}
                                </button>
                              </div>
                            </div>
                          ))
                        : categories.showProducts
                            .slice(activePage * 12, activePage * 12 + 12)
                            .map((ind) => (
                              <div key={ind._id} className={s.card}>
                                <div className="product-carousel-card">
                                  <figure className="fig">
                                    <div className="back">
                                      <div className="clip">
                                        <img
                                          className="img"
                                          src={
                                            ind.image
                                              ? ind.image.indexOf("https") > -1
                                                ? ind.image
                                                : process.env.REACT_APP_API +
                                                  "/uploads/" +
                                                  ind.image
                                              : "test-provod.png"
                                          }
                                          alt=""
                                        />
                                      </div>
                                    </div>
                                  </figure>
                                  <div className="product-carousel-card-body">
                                    <p>
                                      <span className="card-title modify">
                                        {ind.name}
                                      </span>
                                    </p>
                                    <p>
                                      <span className="card-text modify">
                                        {ind.description}
                                      </span>
                                    </p>
                                  </div>

                                  <button
                                    onClick={() =>
                                      navigate("/products/" + ind.id)
                                    }
                                    className={s.button}
                                  >
                                    {t("Кнопка Подробнее")}
                                  </button>
                                </div>
                              </div>
                            ))
                      : "На данный момент, кабелей данного типа нет в наличии"}
                  </div>
                  {categories.showProducts.length > 12 ? (
                    <PaginationProducts
                      activeProducts={categories.showProducts}
                      activePage={activePage}
                      setActivePage={setActivePage}
                      generatePathLocation={generatePathLocation}
                      setSearchParams={setSearchParams}
                      searchParams={searchParams}
                    />
                  ) : (
                    ""
                  )}
                </>
              ) : !filter ? (
                <>
                  <div className={s.cards}>
                    {categories.industries.legal.map((ind) => (
                      <div
                        key={ind.id}
                        onClick={() => {
                          navigate("/products?industriesId=" + ind.id);
                          setActivePage(0);
                          setFilter(1);
                        }}
                        className={`${s.card} ${s.default}`}
                      >
                        <div className={s.cardWrapper}>
                          <img
                            src={
                              ind.image
                                ? ind.image.indexOf("https") > -1
                                  ? ind.image
                                  : process.env.REACT_APP_API +
                                    "/uploads/" +
                                    ind.image
                                : "test-provod.png"
                            }
                            alt="img"
                            className={s.itemImage}
                          />
                        </div>
                        <div className={s.cardContent}>
                          <p className={s.cardTitle}>{ind.name}</p>
                          <p className={s.cardParagraph}>{ind.info}</p>
                        </div>
                      </div>
                    ))}
                  </div>
                </>
              ) : (
                <div className="g-1 product-carousel-row">
                  {leaders.map((elem) => (
                    <>
                      {elem.leaders.map((lead) => (
                        <div key={lead} className={`product-carousel-col`}>
                          <div
                            className="custom-link"
                            onClick={() => {
                              navigate("/products/" + elem.link);
                            }}
                          >
                            <div className="product-carousel-card">
                              <figure className="fig">
                                <div className="back">
                                  <div className="clip">
                                    <img
                                      className="img"
                                      src={"test-provod.png"}
                                      alt="test"
                                    />
                                  </div>
                                </div>
                              </figure>
                              <div className="product-carousel-card-body">
                                <p>
                                  <span className="card-title modify">
                                    {elem.leadersCategory}
                                  </span>
                                </p>
                                <p>{lead}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </>
                  ))}
                </div>
              )}
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}
