import './Awards.css'
import {Row, Col, Image, Breadcrumb} from 'react-bootstrap'
import {Link} from 'react-router-dom'
import {useEffect} from 'react'
import scrollIntoView from 'scroll-into-view'
import Header from '../Header/Header'
import Footer from '../Footer/Footer'
import {useTranslation} from 'react-i18next'

export default function Awards() {
  const {t} = useTranslation()

  useEffect(() => {
    scrollIntoView(document.body.querySelector('#root'), {
      time: 0,
      align: {
        top: 0.1,
      },
    })
  }, [])

  return (
    <>
      <div className="awards-container">
        <Breadcrumb className="custom-breadcrumb" style={{marginTop: '38px'}}>
          <span className={'breadcrumb-item'} onClick={e => e.preventDefault()}>
            <Link to="/">{t('Меню Главная')}</Link>
          </span>
          <span className={'breadcrumb-item'} onClick={e => e.preventDefault()}>
            <Link to="/">{t('Меню Компания')}</Link>
          </span>
          <Breadcrumb.Item active>{t('Наши достижения')}</Breadcrumb.Item>
        </Breadcrumb>
        <div className="commonLabels">{t('Наши достижения')}</div>
        <div className="awards-info">
          <Row>
            <Col className="awards-col-text">
              <p>{t('Наша продукция отмечена')}</p>
              <p>{t('ОАО «Беларускабель» на постоянной основе участвует')}</p>
            </Col>
          </Row>
          <div className="awards-laureat-block">
            <div className="awards-col-text awards-column">
              <p>{t('- «Лучшие товары Республики Беларусь» 2023 год НОВИНКА ГОДА - кабель для сигнализации и блокировки, гибкий СБМВ;')}</p>
              <p>{t('- «Лучшие товары Республики Беларусь» 2022 год НОВИНКА ГОДА - провод барьерный электризуемый БЭ-БЭП.000;')}</p>
              <p>{t('- «Лучшие товары Республики Беларусь» 2021 год - вилка опрессованная 83-16-250-60227 IEC 53;')}</p>
              <p>{t('- «Лучшие товары Республики Беларусь» 2020 год НОВИНКА ГОДА - кабель силовой гибкий КГВЭВнг(А);')}</p>
              <p>{t('- «Лучшие товары Республики Беларусь» 2019 год НОВИНКА ГОДА - кабель плоский лифтовой гибкий;')}</p>
              <p>{t('- «Лучшие товары Республики Беларусь» 2018 год НОВИНКА ГОДА - кабель контроля и передачи данных «Арктический КУПД»;')}</p>
              <p>{t('- «Лучшие товары Республики Беларусь» 2017 год НОВИНКА ГОДА - кабель для систем управления и сигнализации, не распространяющий горения, с изоляцией и оболочкой из полимерных композиций, не содержащих галогенов КПЭПнг(А)-FRHF;')}</p>
              <p>{t('- и ранее.')}</p>
            </div>
            <div className="awards-column">
              <Image
                className="awards-laureat-image"
                src="/awards/laureat.jpg"
                alt="image" />
            </div>
          </div>
          <Row>
            <Col className="awards-col-text">
              <p>{t('Согласно Постановлению от 3 апреля 2020 года')}</p>
              <p>{t('единственный в Республике Беларусь')}</p>
              <p>{t('ОАО «Беларускабель» входит в состав организаций')}</p>
            </Col>
          </Row>
          <div className="awards-certificates-block">
          <Image
              className="awards-laureat-image"
              src="/awards/diplom-army.jpg"
              alt="image"
            />             
          <Image
              className="awards-laureat-image"
              src="/awards/Cabex.png"
              alt="image"
            />            
            <Image
              className="awards-laureat-image"
              src="/awards/1.png"
              alt="image"
            />
            <Image
              className="awards-laureat-image"
              src="/awards/2.png"
              alt="image"
            />
            <Image
              className="awards-laureat-image"
              src="/awards/4.png"
              alt="image"
            />
            <Image
              className="awards-laureat-image"
              src="/awards/5.png"
              alt="image"
            />
          </div>
        </div>
      </div>
    </>
  )
}