import {API_URL} from "./env.js";
import axios from "axios";

const https = axios.create({
    baseURL: API_URL,
});

https.interceptors.request.use(
    (config) => {
        const jwtToken = localStorage.getItem("jwt_token");
        if (jwtToken) {
            config.headers.Authorization = `Bearer ${jwtToken}`;
        }
        return config;
    },
    (error) => Promise.reject(error)
);

export {https}